<template>
    <v-dialog
      max-width="500px"
      v-model="dialog"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          fab
          :top="!inline"
          :right="!inline"
          :absolute="!inline"
          small
          color="color3Text--text color3"
          @click.stop="btnClick"
          :loading="loading"
        >
          <v-icon small>fas fa-lock</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="warning black--text">
          <v-toolbar-title>
            <v-icon>fas fa-exclamation-circle</v-icon>
            Are you sure?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          <div class="subtitle-1 font-weight-medium">The results of {{standings ? 'these standings' : 'this pool'}} influences another round(s).</div>
          Changing any of the finishes here could affect any dependent rounds.
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="center">
              <v-btn
                @click.stop="unlock"
                :disabled="loading"
                :loading="loading"
                text color="success"
              >Unlock</v-btn>
              <v-btn
                :disabled="loading"
                @click.stop="dialog = false"
                text color="error"
              >Cancel</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import TournamentMixin from '@/Mixins/TournamentMixin'

export default {
  mixins: [TournamentMixin],
  props: ['inline', 'warn', 'standings'],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    btnClick () {
      if (this.warn) {
        this.dialog = true
      } else {
        this.unlock()
      }
    },
    unlock () {
      this.loading = true
      this.$emit('unlock-click')
    }
  }
}
</script>

<style>

</style>
